.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000;
  }

  .loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ccc;
    line-height: 0;
    z-index: 1001;
  }

  .imageContainer {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    text-align: center;
    padding: 1em;

    img {
      max-width: calc(100vw - 2em);
      max-height: calc(100vh - 2em);

      &:nth-child(2) {
        // framer-motion creates another image node, so we're just
        // gonna hide it because it results in stuttering
        display: none;
      }
    }
  }

  .button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    font-size: 2em;
    cursor: pointer;
    color: #aaa;
    line-height: 0;
    z-index: 1002;

    .iconContainer {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      padding: 20px;
      background: rgba(0, 0, 0, .3);
    }

    &:hover {
      color: #fff;

      .iconContainer {
        background: #000;
      }
    }

    &.prev, &.prev .iconContainer {
      left: 0;
    }

    &.next, &.next .iconContainer {
      right: 0;
    }

    &.close {
      top: 0;
      right: 0;
      bottom: auto;
      width: auto;

      .iconContainer {
        position: static;
        transform: none;
      }
    }
  }
}
