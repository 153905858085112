.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .item {
    flex: 1 0 25%;
    padding: 2px;
    text-align: center;
    cursor: pointer;

    img {
      max-width: 215px;
      opacity: 0;
      transition: opacity 200ms linear;

      &.loaded {
        opacity: 1;
      }
    }

    @media only screen and ( max-width: 740px ) {
      flex: 1 0 33%;

      img {
        max-width: 100%;
      }
    }

    @media only screen and ( max-width: 560px ) {
      flex: 1 0 50%;
    }
  }
}
