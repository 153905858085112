@import 'colors';

.linkButton {
  i, svg {
    margin-right: 0.5em;
  }

  &.deviantart {
    background: $deviantart;

    &:hover {
      background: lighten($deviantart, 10%);
    }
  }

  &.facebook {
    background: $facebook;

    &:hover {
      background: lighten($facebook, 10%);
    }
  }

  &.youtube {
    background: $youtube;

    &:hover {
      background: lighten($youtube, 10%);
    }
  }
}
